import { Button, Grid, TextField } from '@material-ui/core'
import React, {useContext} from 'react'
import { makeStyles } from '@material-ui/core'
import {FormContext, ErrorsContext} from '../../App'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

const mySpacing = 2
const useStyles = makeStyles(theme =>({
    button: {
        height: 56
    },
    textarea: {
       
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            minWidth: `calc(100% + ${mySpacing *8}px)`
          },
    }
}))

const Contact = ({ loader }) => {

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles()
    const {contact, setContact} = useContext(FormContext)
    const { errors } = useContext(ErrorsContext)

    const handleChange = (e) => {
        setContact({...contact, [e.target.name]: e.target.value})
    }
    return (
        <Grid container className={classes.container} item xs={12} spacing={mySpacing}>
            <Grid item xs={12} sm={4}>
                <TextField multiline={smDown ? false : true} name='msg' value={contact.msg} className={classes.textarea} minRows={13} maxRows={13} label="Uwagi" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid container className={classes.container} item xs={12} sm={8} spacing={mySpacing}>
                <Grid item xs={12}>
                    <TextField name='name' value={contact.name} label="Imię i Nazwisko Klienta" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }}  error={errors.errorNameMsg ? true : false} helperText={errors.errorNameMsg}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField name='phone' value={contact.phone} label="Numer Telefonu" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }}  error={errors.errorPhoneMsg ? true : false} helperText={errors.errorPhoneMsg} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='email' value={contact.email} label="Adres email" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} error={errors.errorEmailMsg ? true : false} helperText={errors.errorEmailMsg}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField name='code' value={contact.code} label="Kod promocyjny (opcjonalnie)" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                    {
                        loader ?
                            <CircularProgress />
                            :
                            <Button className={classes.button} type='submit' color='primary' variant='contained' fullWidth>Wyślij formularz wyceny</Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Contact
