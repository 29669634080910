import React from 'react'
import logoPath from '../../images/logo.png'
import classes from './logo.module.scss'

const Logo = () => {
    return (
            <img className={classes.logo} src={logoPath} alt="logo" />
    )
}

export default Logo
