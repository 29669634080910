import React from 'react'
import { Button,  Typography, Dialog, DialogContent } from '@material-ui/core'
import classes from './formModal.module.scss';

const FormModal = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} >
            <DialogContent className={classes.dialog}>
                <Typography variant='h3'>Twoje zamówienie zostało przekazane do wyceny.</Typography>
                <Typography variant='h5'>Dziękujemy za skorzystanie z naszych usług.</Typography>
                <Typography variant='h5'>Pozdrawiamy aleGniazdka.</Typography>
                <Button className={classes.button} onClick={onClose} variant='contained' color='primary'>Zamknij</Button>
            </DialogContent>
        </Dialog>
    )
}

export default FormModal
