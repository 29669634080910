import React, { useContext } from 'react'
import FormList from '../../component/formList/FormList'
import Contact from '../../component/contact/Contact'
import { FormContext, ErrorsContext } from '../../App'
import _ from 'lodash'
import FormExtras from '../../component/formExtras/FormExtras'
import emailjs from 'emailjs-com';
import { useState } from 'react'
import FormModal from '../../component/formModal/FormModal'
import Header from "../../component/header/Header";
import Subtitle from "../../component/subtitle/subtitle";
import validation from '../../helpers/validation'

const Order = () => {
    const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } = process.env
    const { form, contact, modelInfo } = useContext(FormContext)
    const { setErrors } = useContext(ErrorsContext)
    const { name, phone, email, code, msg } = contact
    const { company, model, inside_line, inside_color, inside_material, frame_line, frame_color, frame_material } = modelInfo
    const [openModal, setOpenModal] = useState(false)
    const handleCloseModal = () => {
        setOpenModal(!setOpenModal)
        window.location.reload(true);
    }

    const [loader, setLoader] = useState(false)

    const sendData = async (toSend,e) => {
        setLoader(true)
        await emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, toSend, REACT_APP_USER_ID)
        setOpenModal(true)
        setLoader(false)
        e.target.reset()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const formErrors = validation({ ...contact, company })
        setErrors(formErrors)
        if (Object.keys(formErrors).length === 0) {

            let products = ``

            for (const key in form) {
                products = `${products}<p>${key}: ${form[key]}</p>`
            }
            const toSend = {
                products,
                name,
                phone,
                email,
                code,
                msg,
                company,
                model,
                inside_line,
                inside_color,
                inside_material,
                frame_line,
                frame_color,
                frame_material
            }
            sendData(toSend, e)
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Header/>
                <Subtitle title="Szczegóły linii produktowej"/>
                <FormExtras />
                <Subtitle title="Lista produktów"/>
                <FormList />
                <Subtitle title="Dane Kontaktowe" />
                <Contact loader={loader}  />
            </form>
            <FormModal open={openModal} onClose={handleCloseModal} />
        </>
    )
}

export default Order
