import {List } from '@material-ui/core'
import React, {useContext} from 'react'
import {StuffContext} from '../../App'
import FormListCategory from '../formListCategory/FormListCategory';


const FormList = () => {
    const {sortedArr, categoryName} = useContext(StuffContext)
    return (
        <List component="nav" aria-labelledby="nested-list-subheader">
            {
                sortedArr.map((category, index) => <FormListCategory key={index} category={category} categoryName={categoryName[index]}/>)
            }
        </List>
    )
}

export default FormList
