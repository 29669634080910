import React, {useState} from 'react'
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core';
import CategoryRow from '../categoryRow/CategoryRow';
import { ExpandMore  } from '@material-ui/icons';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    listItemText: {
        flex: '0 1 auto',
        marginRight: xsDown => xsDown ? 'auto' : 80
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

const FormListCategory = ({category, categoryName}) => {
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles(xsDown);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <div>
            <ListItem button onClick={handleClick} style={{justifyContent: 'space-between'}}>
                <ListItemText className={classes.listItemText} primary={categoryName} />
                {open ? <ExpandMore /> : <NavigateNextIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="ul" disablePadding>
                    {
                        category.map((product, index) => <CategoryRow key={index} product={product}/>)
                    }
                </List>
            </Collapse>
        </div>
    )
}

export default FormListCategory
