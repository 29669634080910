import React, { useContext } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { FormContext, ErrorsContext } from '../../App'
import { makeStyles } from '@material-ui/core'

const mySpacing = 2
const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            minWidth: `calc(100% + ${mySpacing * 8}px)`
        },
        [theme.breakpoints.up('md')]: {
            minWidth: `calc(50% + ${mySpacing * 8}px)`
        },
    }
}))

const FormExtras = () => {
    const classes = useStyles()
    const { modelInfo, setModelInfo } = useContext(FormContext)
    const { errors } = useContext(ErrorsContext)
    const { company, model, inside_line, frame_line, inside_color, frame_color, inside_material, frame_material } = modelInfo
    const handleChange = (e) => {
        setModelInfo({ ...modelInfo, [e.target.name]: e.target.value })
    }
    return (
        <Grid className={classes.container} container item xs={12} md={12} spacing={mySpacing}>
            <Grid item xs={12}>
                <TextField
                    name='company'
                    value={company}
                    label="Producent"
                    autoComplete='off'
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ autoComplete: 'none' }}
                    error={errors.errorCompanyMsg ? true : false}
                    helperText={errors.errorCompanyMsg}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField name='model' value={model} label="Model" autoComplete='off' variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='inside_line' value={inside_line} autoComplete='off' label="Linia wnętrza" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='frame_line' value={frame_line} autoComplete='off' label="Linia ramki" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='inside_color' value={inside_color} label="Kolor wnętrza" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='frame_color' value={frame_color} label="Kolor ramki" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='inside_material' value={inside_material} label="Materiał wnętrza" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name='frame_material' value={frame_material} label="Materiał ramki" variant="outlined" fullWidth onChange={handleChange} inputProps={{ autoComplete: 'none' }} />
            </Grid>
        </Grid>
    )
}

export default FormExtras
