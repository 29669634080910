import React from 'react'
import { useContext } from 'react'
import { FormContext } from '../../App'
import { Box, Grid, ListItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    listItem: {
        alignItems: 'flex-end',
        marginBottom: smDown => smDown && '20px',
        fontSize: smDown => smDown && '14px'
    },
    box: {
        borderBottom: `1px solid ${theme.palette.grey['300']}`,
        paddingBottom: 10,

    },
    boxSmall: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    textField: {
        flex: '0 1 100px',
    },
    container: {
        alignItems: 'flex-end',
        spacing: smDown => smDown ? '1' : '2' 
    }
}))

const CategoryRow = ({ product }) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles(smDown)
    const { form, setForm } = useContext(FormContext)
    const localName = `${product.category} ${product.a} ${product.b} ${product.c}`
    const handleChange = (e) => {

        setForm({ ...form, [e.target.name]: e.target.value })
    }

    return (
        <ListItem className={classes.listItem}>
            <Grid className={classes.container} container spacing={smDown ? 1 : 2}>
                <Grid className={classes.grid} item xs={2} sm={1}>
                    <Box className={`${classes.box} ${classes.boxSmall}`}>{product.id}</Box>
                </Grid>
                <Grid className={classes.grid} item xs={5} sm={3}>
                    <Box className={classes.box}>{product.a}</Box>
                </Grid>
                <Grid className={classes.grid} item xs={5} sm={3}>
                    <Box className={classes.box}>{product.b}</Box>
                </Grid>
                {
                    smDown && <Grid item xs={2}></Grid>
                }
                <Grid className={classes.grid} item xs={5} sm={3}>
                    <Box className={classes.box}>{product.c}</Box>
                </Grid>
                <Grid className={classes.grid} item xs={5} sm={2}>
                    <TextField className={classes.textField} label='ilość' variant='outlined' name={ localName} type='text' value={form[localName] ? form[localName] : ''} onChange={handleChange} />
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default CategoryRow
