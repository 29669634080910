const validation = (data) => {
    //eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const errors = {}
    const { name, phone, email, company } = data

    //check company
    if (company === '') {

        errors.errorCompanyMsg = 'Pole Producent nie może być puste'
    }


    //check name
    if (name === '') {

        errors.errorNameMsg = 'Pole Imię i Nazwisko klienta nie może być puste'
    }


    //check phone
    if (phone === '') {

        errors.errorPhoneMsg = 'Pole Number Telefonu nie może być puste'
    }


    //check email
    if (email === '') {

        errors.errorEmailMsg = 'Pole Adres mail nie może być puste'
    }
    else {
        if(!emailRegex.test(email)) {
 
            errors.errorEmailMsg = 'Podany Adres Email nie jest poprawny'
        }
    }
    

    return errors
}

export default validation