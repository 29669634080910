import React from 'react';
import Logo from '../../component/logo/Logo'
import {Grid} from "@material-ui/core"
import classes from './header.module.scss'

const Header = () => {
    return (
        <Grid container alignItems='center' className={classes.header} >
            <Grid item xs={12}>
                <Logo/>
            </Grid>
            <Grid item xs={12}>
                <h1>Formularz Zapytania Ofertowego</h1>
                <p style={{marginBottom: '0px'}}>
                    <h3>Hurtownia Elektryczna BEMA</h3>
                    <p>Kościerska 11<br/>
                        83-300 Kartuzy</p>
<p><a href={"tel:603479628"}>tel: 603-479-628</a><br/>
email: <a href={"mailto:info@alegniazdka.pl"}>info@alegniazdka.pl</a>
</p>
                </p>
            </Grid>
        </Grid>
    )
}


export default Header
