import React from 'react'
import classes from './subtitle.module.scss'

const Subtitle = (props) => {
    return (
        <div className={classes.wrapper}>
            <h2 className={classes.title}>{props.title}</h2>
            <div className={classes.line}></div>
        </div>
    )
}

export default Subtitle