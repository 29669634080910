import React from 'react';
import './scss/main.scss';
import Papa from 'papaparse'
import filePath from './basic-inquiry-form-data.csv'
import { useState, createContext } from 'react';
import Order from './pages/order/Order';
import sort from './utils/sort'
import theme from './theme/Theme';
import { MuiThemeProvider } from "@material-ui/core";

export const StuffContext = createContext()
export const FormContext = createContext()
export const ErrorsContext = createContext()
export const categoryName = [
    'ŁĄCZNIKI',
    'PRZYCISKI',
    'GNIAZDA 230V',
    'GNIAZDA ANTENOWE (R, TV, SAT)',
    'GNIAZDA TELEKOMUNIKACJNE',
    'GNIAZDA MULTIMEDIALNE',
    'RAMKI',
    'AKCESORIA',
    'PRODUKTY ELEKTRONICZNE'
]

function App() {
    const [form, setForm] = useState('')
    const [contact, setContact] = useState({
        name: '',
        phone: '',
        email: '',
        code: '',
        msg: ''
    })
    const [modelInfo, setModelInfo] = useState({
        company: '',
        model: '',
        inside_line: '',
        inside_color: '',
        inside_material: '',
        frame_line: '',
        frame_color: '',
        frame_material: '',
    })
    const [sortedArr, setSortedArr] = useState([])

    const [errors, setErrors] = useState([])

    document.title = 'Formularz Zamówienia';

    if (sortedArr.length === 0) {
        Papa.parse(filePath, {
            download: true,
            header: true,
            complete: async function (results) {
                const arr = await results.data
                const newArr = arr.map(item => {
                    switch (parseInt(item.id)) {
                        case 1: return { categoryNumber: parseInt(item.id), category: categoryName[0], ...item }
                        case 2: return { categoryNumber: parseInt(item.id), category: categoryName[1], ...item }
                        case 3: return { categoryNumber: parseInt(item.id), category: categoryName[2], ...item }
                        case 4: return { categoryNumber: parseInt(item.id), category: categoryName[3], ...item }
                        case 5: return { categoryNumber: parseInt(item.id), category: categoryName[4], ...item }
                        case 6: return { categoryNumber: parseInt(item.id), category: categoryName[5], ...item }
                        case 7: return { categoryNumber: parseInt(item.id), category: categoryName[6], ...item }
                        case 8: return { categoryNumber: parseInt(item.id), category: categoryName[7], ...item }
                        case 9: return { categoryNumber: parseInt(item.id), category: categoryName[8], ...item }
                        default: return item
                    }
                })
                setSortedArr(sort(newArr))
            }
        });
    }



    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <FormContext.Provider value={{ form, setForm, contact, setContact, modelInfo, setModelInfo }}>
                    <StuffContext.Provider value={{ sortedArr, categoryName }} >
                        <ErrorsContext.Provider value={{errors, setErrors}}>
                            <Order />
                        </ErrorsContext.Provider>
                    </StuffContext.Provider>
                </FormContext.Provider>
            </div>
        </MuiThemeProvider>
    );
}

export default App;
